@use "~react-modal-video/scss/modal-video.scss";

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
}

html {
  font-family: 'Bowlby One SC', sans-serif;
  font-size: 12px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  display: block;
  text-decoration: none;
  color: #009af3;
}

.container {
  max-width: 80rem;
  margin: 0 auto;
  padding: 0 2.2rem;
  &--logo {
    max-width: none;
    display: flex;
    flex-wrap: wrap;
  }
  &--nav {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    padding: 0;
    z-index: 1;
  }
  &--social-links {
    max-width: none;
    margin: 0;
    padding: 0;
    left: 0;
    right: 0;
    position: absolute;
    display: flex;
  }
}

header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 8rem;
  background-color: rgba(0, 0, 0, .7);
  backdrop-filter: blur(2rem);
  display: flex;
  z-index: 1;
}

video.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  object-fit: cover;
  opacity: 0.8;
  height: 56rem;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 56rem;
  background: black;
  opacity: 0.4;

  &.blend-mode {
    mix-blend-mode: color;
  }
}

.nav-list {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 8rem;
}

.nav-list-mobile {
  display: none;
}

.nav-link {
  font-size: 2rem;
  color: #fff;
  padding: 0 2rem;
  transition: opacity .5s;
}

.nav-link:hover {
  opacity: .7;
}

.nav-link-discord {
  background: url("../images/discord_button.png") no-repeat;
}

.nav-link-twitter {
  background: url("../images/twitter_button.png") no-repeat;
}

.nav-link-facebook {
  background: url("../images/facebook_button.png") no-repeat;
}

.nav-link-discord, .nav-link-twitter, .nav-link-facebook {
  width: 4rem;
  height: 4rem;
  padding: 0;
  background-size: contain;
  display: inline-block;
  cursor: pointer;
  margin: 0 0.4rem;

  &:hover {
    width: 4rem;
    height: 4rem;
  }
}

.nav-link-mobile {
  display: none;
}


.section {
  margin-top: 8rem;
}

.headline {
  position: relative;
  .title {
    color: white;
  }
  .title-sub-note {
    color: #ddd;
  }
  .container {
    width: 48rem;
  }
}

.howto {
  margin-top: 2rem;
  height: inherit;
  .container {
    max-width: 70rem;
  }

  .wireframes {
    img {
      max-width: 100%;
    }
  }
}

.items, .starter-package {
  margin-top: 2rem;
  .container {
    max-width: 70rem;
  }
  .title {
    padding-top: 1rem;
  }
  .item-wrapper {
    margin: 3rem auto;
  }
  .item-block {
    justify-content: space-evenly;
    display: flex;
    margin-bottom: 2rem;
  }
  .item-single {
    display: flex;
    max-width: 25rem;
    background-color: #eee;
    border-radius: 8px;
    padding: 2rem;
    min-height: 7.5rem;

    div:first-child {
      margin-right: 1rem;
      align-self: center;
    }
    div:last-child {
      align-self: center;
      font-size: 1.06rem;
      color: #454545;
    }
    .stable-bag {
      height: 5rem;
    }
  }
  img.item {
    height: 7rem;
  }
}

.lobby-types, .characters {
  margin-top: 2rem;
  .container {
    max-width: 70rem;
  }
  .item-wrapper {
    display: flex;
    margin: 3rem auto;
    justify-content: center;
  }

  .item-content {
    div:first-child {
      margin-right: 1rem;
    }

    div:last-child {
      text-align: center;
      color: #767676;
      font-size: 1.1rem;
    }
  }

  img {
    height: 12rem;
  }

  .item-single {
    padding: 2rem;
    background-color: aliceblue;
    border-radius: 8px;
    margin: 0 1rem;
    max-width: 26rem;

    div:first-child {
      text-align: center;
      font-size: 1.8rem;
      color: #454545;
      margin-bottom: 1.5rem;
    }
  }
}

.characters {
  margin-top: 2rem;
  .container {
    max-width: 70rem;
  }
  .item-wrapper {
    text-align: center;
    display: inherit;
    margin: inherit;
  }
  .item-single {
    background-color: #eee;
    max-width: 11rem;
    min-width: 11rem;
    min-height: 20rem;
    margin: 1rem;
    div:first-child {
      margin-bottom: auto;
    }
    div:last-child {
      margin-top: 1rem;
      color: #757575;
      font-size: 0.9rem;
    }
    &.cop, &.robber {
      background-color: #eee;
      display: inline-block;
      vertical-align: top;

      div:first-child {
        margin-top: -0.5rem;
        margin-bottom: 1rem;
      }
    }
    img {
      height: 11rem;
    }
  }

  .item-content {
    div:first-child {
      margin-right: auto;
    }
  }
}

.starter-package {
  margin-top: 6rem;
  margin-bottom: 6rem;
  .container {
    max-width: 70rem;
  }
}

.game {
  margin-top: 1rem;
  height: inherit;
  .container {
    max-width: 70rem;
  }
  .game-features {
    display: flex;
    margin-top: 1.5rem;
  }
  ul {
    list-style-type: none;
    font-size: 1.5rem;
    color: #666;
    flex: 1;
    text-shadow: -1px 0 #c0c0c0, 0 1px #c0c0c0, 1px 0 #c0c0c0, 0 -1px #c0c0c0;
  }
  li {
    margin: 0.5rem 0;
    padding: 0.6rem 10rem 0.6rem 2rem;
    color: white;
    border-radius: 8px;
    background: linear-gradient(to right, #d7d7d7 50%, #FFF);
  }
}


.privacy, .press {
  .container {
    max-width: 70rem;
  }
  a:hover {
    text-decoration: underline;
  }

  .title-sub-note {
    text-align: left;
    font-size: 1.5em;

  }
}

.press {
  .item-container.press {
    background-color: #eee;
    display: inline-block;
    padding: 2rem;
    border-radius: 8px;
    margin: 1rem;
    min-width: 24rem;
    img {
      height: 12rem;
      margin: 1.2rem auto;
    }
    a {
      font-size: 1rem;
    }
    div:first-child {
      font-size: 1.2rem;
      color: #454545;
    }
  }
  .team-wrapper {
    img {
      max-width: 100%;
    }
  }

  .title-sub-note {
    text-align: center;
    font-size: 1.5em;

  }
}

.nomatch, .donation-complete {
  min-height: calc(100vh - 513px);
  .container {
    max-width: 70rem;
  }
  .title-sub-note {
    font-size: 1.2rem;
    text-align: left;
    margin: 3rem auto;
  }
  ul {
    padding-left: 3rem;
    margin: 1rem auto;
  }
  a {
    display: inline;
    &:hover {
      text-decoration: underline;
    }
  }

  img {
    width: 11rem;
    margin-bottom: 1rem;
  }

  :last-child div {
    margin-right: 1rem;
    align-self: center;
  }
}

.donation-complete {
  .container {
    max-width: 69rem;
  }

  &:last-child div {
    padding: 0 0.5rem;
  }
  p {
    margin-bottom: 2rem;
  }
}

.logo {
  background-image: url("../images/logo_transparent_purple.png");
  background-size: auto 14rem;
  height: 14rem;
  background-repeat: no-repeat;
  width: 28rem;
  margin: 0.5rem;
}

.title {
  text-align: center;
  padding-top: 5.5rem;

  &.policy, &.donation-complete, &.nomatch {
    font-size: 0.7rem;
    padding-bottom: 5.5rem;
  }
}

.title-heading {
  font-size: 5.6em;
  line-height: 1.07143;
  letter-spacing: -.005rem;
}

.title-sub-heading {
  font-size: 2.65em;
  margin-top: .6rem;
  letter-spacing: .008rem;
  font-weight: 400;
  &.privacy, &.press {
    margin-top: 4rem;
  }
}

.title-sub-note {
  font-size: 1.7em;
  color: #888;
  margin-top: .68rem;
}

.img-sketch-wrapper {
  display: grid;
  height: 34rem;
}

.img-sketch-wrapper div {
  grid-column: 1;
  grid-row: 1;
  background-size: contain;
  background-repeat: no-repeat;
  transition: opacity 0.1s;
  margin-left: 1rem;
}

.cta-button {
  margin: 4rem 2rem;
  &.cta-button-apple {
    height: 6rem;
    width: 20rem;
    background: url("../images/apple_button.png") no-repeat;
    background-size: contain;
  }

  &.cta-button-google {
    height: 6rem;
    width: 20rem;
    background: url("../images/google_button.png") no-repeat;
    background-size: contain;
  }
}


footer {
  background-color: #f2f2f2;
  padding: 0 22px 21px;
  overflow: hidden;

  .footer-content {
    max-width: 90rem;
    min-height: 22rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    &--wrapper {
      display: flex;
      justify-content: space-between;
    }
  }

  .footer-legal-copyright {
    margin-right: 30px;
    float: left;
    margin-top: 3px;
  }

  .footer-legal-links a {
    border-right: 1px solid #d6d6d6;
    margin-right: 10px;
    padding-right: 12px;
    display: inline-block;
    margin-top: 3px;
    white-space: nowrap;
    color: #555;

    &:hover {
      text-decoration: underline;
    }
  }

  .footer-legal-links a:last-child {
    border-right: none;
  }
}

.mail-subscription, .donation {

  background: #e5f3fc;
  padding: 1.5rem 2rem;
  margin-bottom: 1rem;
  border-radius: 16px;
  border: 2px solid #CBECFA;
  width: 40rem;

  &--title {
    padding-bottom: 0.5rem;
    color: #454545;
    font-size: 1.5rem;
    padding-left: 0.5rem;
  }

  &--subtitle {
    padding-bottom: 0.5rem;
    color: #767676;
    font-size: 1rem;
    padding-left: 0.5rem;
  }

  > :nth-child(3) > div {
    padding-left: 0.5rem;
    padding-bottom: 0.5rem;
  }

  input, button {
    font-family: inherit;
    border-radius: 8px;
    position: relative;
  };

  button {
    padding: 1.5rem;
    margin-left: 1.5rem;
    border: none;
    cursor: pointer;
  }

  input {
    font-size: 1.4rem;
    padding: 1rem 1.3rem;
    width: 28rem;
    border: 2px solid transparent;
    height: 2.5rem;
    color: #696158;
    border-radius: 8px;
    &:focus {
      outline:none;
      border: 2px solid #5cc5f6;
      transition: 0.5s;
    }
  }
}

.donation {
  &--button {
    width: 10rem !important;
    height: 4rem !important;
    margin: 0;
    background-color: #E9E9ED;

    &:hover {
      background-color: #D0D0D7;
    }
  }
  .donation-wrapper {
    display: flex;
  }
}


@media screen and (max-width: 1068px) {

  header {
    height: 4.8rem;
    transition: background .36s cubic-bezier(0.32, 0.08, 0.24, 1),
                height .56s cubic-bezier(0.32, 0.08, 0.24, 1);
  }

  .container {
    max-width: inherit;
    &--nav {
      z-index: 2;
      display: flex;
      justify-content: end;
    }
  }

  .nav-list-mobile {
    display: flex;
    height: 4.8rem;
  }

  .nav-item {
    width: 4.8rem;
    height: 4.8rem;
    display: flex;
    justify-content: center;
  }

  .nav {
    position: relative;
  }

  .nav-link {
    font-size: 1.7rem;
    padding: 0;
    margin: auto 0;
  }

  .nav-list-larger {
    position: fixed;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    display: block;
    padding: 10.5rem 5rem;
    z-index: 1;
    box-sizing: border-box;
    opacity: 0;
    visibility: hidden;
    transition: opacity .3s;
  }

  .active .nav-list-larger {
    width: 100%;
    height: 100vh;
    opacity: 1;
    visibility: visible;
  }

  .nav-list-larger .nav-item {
    width: 100%;
    justify-content: flex-start;
    border-bottom: 2px solid rgba(255, 255, 255, .1);
  }

  .active .nav-list-larger .nav-item {
    animation: fadeIn 1s ease-in;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .nav-link-discord, .nav-link-facebook, .nav-link-twitter {
    width: 2.5rem;
    height: 2.5rem;
    display: none;
    &:hover {
      width: 2.5rem;
      height: 2.5rem;
    }
  }

  .nav-link-mobile {
    display: inherit;
    margin-right: 0.3rem;
  }
  
  .mobile-menu {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .line {
    position: absolute;
    width: 1.7rem;
    height: 1px;
    background-color: #fff;
    transition: margin-top .3192s cubic-bezier(0.04, 0.04, 0.12, 0.96);
  }

  .line-top {
    margin-top: 3px;
  }

  .line-bottom {
    margin-top: -.4rem;
  }

  .active .line-top {
    margin-top: 0;
    transform: rotate(45deg);
    transition: transform .3192s .1s cubic-bezier(0.04, 0.04, 0.12, 0.96);
  }

  .active .line-bottom {
    margin-top: 0;
    transform: rotate(-45deg);
    transition: transform .3192s .1s cubic-bezier(0.04, 0.04, 0.12, 0.96);
  }

  .logo {
    background-size: auto 7rem;
    height: 7rem;
    width: 14rem;
  }

  header.active {
    height: 100%;
    background-color: #000;
  }

  .title {
    padding-top: 2.4rem;
  }

  .title-heading {
    font-size: 3.2rem;
  }

  .title-sub-heading {
    font-size: 1.9rem;
  }
  .title-sub-note {
    font-size: 1.2rem;
  }

  .headline {
    .container {
      width: auto;
      max-width: 28rem;
    }
  }

  .cta-button {
    margin: 4rem 1rem;
    &.cta-button-apple {
      width: 10rem;
      height: 3rem;
    }

    &.cta-button-google {
      width: 10rem;
      background-size: contain;
    }
  }
  video.background, .overlay {
    height: 40rem;
  }

  .game {
    margin-top: 0;
    .container {
      max-width: 50rem;
    }
    .title {
      padding-top: 1.4rem;
    }
    .game-features {
      flex-direction: column-reverse;
    }

    .img-sketch-wrapper {
      justify-content: center;
      margin-right: 2rem;
      margin-top: 1rem;
      margin-bottom: 2rem;
    }
  }

  .howto {
    .container {
      max-width: 50rem;
    }
  }

  .items {
    .container {
      max-width: 50rem;
    }
    .item-block {
      flex-direction: column;
      flex-wrap: wrap;
      align-content: center;
      margin-bottom: 0;
    }

    .item-single {
      margin: 1rem auto;
    }
  }

  .lobby-types {
    .container {
      max-width: 50rem;
    }
    .item-wrapper {
      flex-direction: column;
      flex-wrap: wrap;
      align-content: center;
      margin: 0;
    }
    .item-single {
      margin: 1rem auto;
    }
  }

  .characters {
    .container {
      max-width: 50rem;
    }
  }

  .starter-package {
    .container {
      max-width: 50rem;
    }
    .item-block {
      flex-direction: column;
      flex-wrap: wrap;
      align-content: center;
      margin: 0;
    }
    .item-single {
      margin: 1rem auto;
    }
  }
  .footer-content {
    &--wrapper {
      flex-direction: column;
    }
  }
  .mail-subscription {
    margin-top: 2rem;
    align-self: center;
    max-width: 50rem;
  }
  .mail-subscription, .donation {
    width: auto;
  }

  .donation {
    align-self: center;
    max-width: 50rem;
  }

  .donation-wrapper {
    display: flex;
    flex-direction: column;
  }

  .mail-subscription input {
    width: calc(100% - 3rem);
  }

  .mail-subscription button {
    margin-left: 0;
    margin-top: 1rem;
  }

  .img-sketch-wrapper {
    height: 26rem;
    div {
      width: 22rem !important;
    }
  }
  .press {
    .item-container.press {
      min-width: 20rem;
      img {
        height: 10rem;
      }
    }
  }
}